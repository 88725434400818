<template>
    <div>
        <div class="cloudbanner">
          <div>
              <h1>Our Product</h1>
          </div>
        </div>

        <div class="divider"></div>

        
        <div class="right-mobile"> <!-- Section that will only appear isn Mobile -->
            <img src="/images/litefirstorange.svg" id="litefirstorange">
            <h2>Plug & play system</h2>
        </div>

        <div class="top">
          <div class="left">
              <img src="/images/lantern1.jpg" id="lantern2">
              <img src="/images/smalllantern.jpg" id="smalllantern">
              <img src="/images/smallgarden.jpg" id="smallgarden">
          </div>
          <div class="right">
            <div>
            <img src="/images/litefirstorange.svg" id="litefirstorange">
            <h2>Plug &amp; play system</h2>
            </div>

              <div class="bulletpoints">
                <ul>
                  <li>Short Detachable Cables for ease of installation</li>
                  <li>75cm of cable from each light with plug</li>
                  <li>WiFi Controllable via third party app on Smartphone giving ON/OFF, Dimming and Timer Control</li>
                  <li>Compatible with Google Home and Amazon Alexa</li>
                  <li>Mains plug compatible with standard outdoor socket</li>
                  <h3>Specification (each light)</h3>
                  <li>Light Output: 340 Lumens</li>
                  <li>Power Rating: 2.4 Watts</li>
                </ul>
              </div>

            <div class="details">
              <p>
                Prototype Starter Packs are available comprising 3 Lights, 12Volt 24Watt Outdoor Power Supply, Wifi Controller (optional without) and 18 Metres of cable* for a reduced price of only £99.99
              </p>
              <p>
                *4 Metre cable fixed from WiFi Controller/Power Supply, 4 Metre extension cable, 2 x 2 Metre extension cables and 2 x 3 Metre extension cables to allow maximum flexibility for light positioning
              </p>
              <p>
                Extra lights and cables are available to pre-order
              </p>
              <p>
                Extra lights come with T connector and 5 Metres of cable
              </p>
              <p>
                Maximum of 6 lights per system over a 40 Metre cable run
              </p>
              <p>
                Lead time approx. 2-3 weeks
              </p>
              <p>
                Express interest by Pre-Ordering below
              </p>
            </div>

            <div class="orderbutton">
              <router-link to="/pre-order">Pre Order Here</router-link>
            </div>

          </div>
          
        </div>

      <div class="brightlighting">
        <h1>Bright Lighting Using Low Power</h1>
      </div>

    <AddOns/>      
        
    </div>
</template>

<script>

import AddOns from '../components/AddOns.vue'

export default {
  name: 'OurProduct',
  components: {
    AddOns
  }

}
</script>

<style scoped>
.cloudbanner {
  background-image: url("/images/cloudthin.jpg");
  background-size: cover;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloudbanner h1 {
  position: relative;
  color: white;
  font-size: 100px;
  font-family: turbinado-pro;
  font-weight: 400;
  text-shadow: 2px 2px 5px #595a5c;
  margin: 4rem 0;
}

.top {
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;
}

.right-mobile {
  display: none;
}

.top > .right {
  width: 520px;
  margin: 10px;
  align-items: left;
  justify-content: left;
  text-align: left;
  font-weight: 700;
  font-size: 1.3rem;
}

.top > .right h2{
  font-size: 3rem;
  margin: 0.5rem;
  font-family: bebas-neue-rounded;
}

.top > .left {
  width: 461px;
  margin: 10px;
  text-align: left;
  line-height: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top img{
  margin-bottom: 1rem;
}

#litefirstorange {
  position: relative;
  width: 300px;
 
}

.top > .right > h2{
  position: absolute;
  color: #595a5c;
  font-family: bebas-neue-rounded;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
}

.bulletpoints {
  position: relative;
  margin-top: 0;
}

 li {
   position: relative;
   line-height: 2;
   list-style-position: outside;
   margin-top: 0;
   padding: 0;
   font-family: din-2014;
 }

ul h3 {
  color: #ca6b35;
  font-family: din-2014;

 }

.details{
  line-height: 1.8;
}

.orderbutton {
  position: relative;
  background-color: #ca6b35;
  text-decoration: none;
  border-radius: 0.6rem;
  color: white;
  text-align: center;
  padding: 2rem 3rem;
  font-size: 2rem;
  font-weight: 400;
}

.orderbutton a{
  text-decoration: none;
  color: inherit;
}

.details > .right {

  width: 710px;
  margin: 0;
  align-items: left;
  justify-content: left;
  text-align: left;
  line-height: 30px;
  font-family: din-2014;
}

.details > .left {
  width: 710px;
  margin: 0;
  align-items: left;
  justify-content: left;
  text-align: left;
  line-height: 75px;
}

.brightlighting {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.brightlighting h1 {
  margin: auto;
  font-family: turbinado-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #595a5c;
  font-size: 6rem;
  margin: 2rem 0 0 0;
}




@media screen and (max-width: 1800px){

  .brightlighting {
    width: 100%;
    
  }
}

@media screen and (max-width: 1120px){

  .top > .left {
    width: 461px;
    margin: 1rem;
    text-align: left;
    line-height: 75px;
  }

  .top > .right {
    width: 450px;
    margin: 1rem;
    text-align: left;
    line-height: 75px;
  }

  .top{
    display: inherit;
    margin: 1rem;
  }

  .top > .left{
    float: left;
    padding-right: 2rem;
  }

  .top > .right{
    display: inline;
  }

}

@media screen and (max-width: 900px){
  .top{
    flex-direction: column;
  }

  .top .right {
    width:auto;
    height: auto;
  }

  .brightlighting{
    padding: 2rem;
    width: calc(100% - 4rem);
  }

  .top > .left{
    width: 100%;
  }

  .top > .left img{
    width: calc( 100% - 2rem );
  }

}

@media screen and (max-width: 768px){

  #lantern2 {
    margin-bottom: 1rem;
  }

  .top  > .left {
    flex-direction: column;
  }

  .orderbutton h2 {
    margin: auto;
    padding: 1rem;
    
  }

}

@media screen and (max-width: 600px){
  .brightlighting {
    width: calc( 100% - 4rem );
  }

  .brightlighting h1{
    font-size: 20vw;
  }


}

@media screen and (max-width: 450px){
  .brightlighting h1{
    width: calc( 100% - 4rem );
  }

}

 @import url("https://use.typekit.net/fja2htz.css");
 @import url("https://use.typekit.net/evq5kgu.css");
 @import url("https://use.typekit.net/wuh1hlk.css");
 @import url("https://use.typekit.net/qoa5jej.css");
</style>