<template>
  <div id="app">
    <!-- Session ID is required by PayPal script in order to identify the payment -->
    <input type="hidden" id="sessionId" :value="sessionId">

    <Header/>

    <!--- Main contnet -->

      <router-view />

    <!-- End of main content -->

    <div class="divider"></div>

    <Footer/>

  </div>

  
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {
    sessionId: function(){
      return this.$store.getters.getSession;
    }
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto;
  max-width: 1920px;
  overflow: hidden;
  /*
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 0 1.5rem rgba( 0, 0, 0, .4 );
  */
}

body {
  margin: 0;
  box-sizing: border-box;
}

img{
  max-width: 100%;
}

.divider {
  background-color: #595a5c;
  position: relative;
  margin: auto;
  height: 32px;
}

@import url("https://use.typekit.net/fja2htz.css");
 @import url("https://use.typekit.net/evq5kgu.css");
 @import url("https://use.typekit.net/wuh1hlk.css");
 @import url("https://use.typekit.net/qoa5jej.css");

</style>
