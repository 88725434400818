import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AboutUs from '../pages/AboutUs.vue'
import OurProduct from '../pages/OurProduct.vue'
import PreOrder from '../pages/PreOrder.vue'
import Thankyou from '../pages/Thankyou.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About us',
    component: AboutUs
  },
  {
    path: '/our-product',
    name: 'Our Product',
    component: OurProduct
  },
  {
    path: '/pre-order',
    name: 'Pre Order',
    component: PreOrder
  },
  {
    path: '/thank-you',
    name: 'thank you',
    component: Thankyou
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
