<template>
    <div>
          <div class="wrapper">
            <div>
                <div class="circle"><img src="/images/starterpack.png" alt=""></div>
                <div class="info">
                  <h1>Starter Pack</h1>
                  <h3>The system will be available as a starter pack that includes; 3 Lights, 18 Metres of Cable, Waterproof Power Supply and WiFi Controller</h3>
                </div>
            </div>

            <div>
                <div class="circle"><img src="/images/addon.png" alt=""></div>
                <div class="info">
                  <h1>Add-Ons</h1>
                  <h3>As a modular system, extra lights can be added individually up to a maximum of 6 over a 40 metre cable length</h3>
                </div>
            </div>

            <div>
                <div class="circle"><img src="/images/wifi.png" alt=""></div>
                <div class="info">
                  <h1>WiFi Module</h1>
                  <h3>WiFi module comes as standard for Smartphone control via third party app Compatible with Google Home/Amazon Alexa</h3>
                </div>
            </div>

          </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  margin: auto;
}

.wrapper > div {
  margin: 5rem;
}

.circle {
  background-color: #c96a36;
  width: 100%;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-items: center;
}

.info {
  width: 261px;

}

  
@media screen and (max-width: 1120px){
.wrapper > div {
  width: 261px;
  height: 520px;
  margin: 2rem;
}

}

@media screen and (max-width: 1000px){

  .wrapper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 1000px;
    margin: auto;
  }

  .wrapper > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width:auto;
    height: 250px;
    
  }

  .circle {
  background-color: #c96a36;
  width: 281px;
  height: 281px;
  border-radius: 50%;

  }

  .info {
    width: 50vw;
    height: auto;
    margin: auto;

  }
}

@media screen and (max-width: 600px){

  .wrapper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    height: auto;
  }

  .wrapper > div {
    height: auto;
    margin: auto;
    flex-direction: column;
  }

  .wrapper .circle{
    max-width: 60vw;
    max-height: 60vw;
  }

}

</style>