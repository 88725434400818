<template>
  <div class="home">
    
    <div class="cloud">

      <div>
        <img src="/images/litefirsrtwhitelogo.png" id="litefirstwhitelogo">
        <h1>Making Light work for you!</h1>
      </div>

    </div>

    
      
    <div class="garden1">
      <div class="gardeninner">
        <h1>"Lighting your garden beautifully without dazzle or glare"</h1>
      </div>
    </div>

    <Carousel/>

    <div class="divider"></div>

    
        <div class="lantern">
            <img src="/images/lantern1.jpg" id="lanternimage">
            <h3>Using a Classic Lantern, Litefirst introduces a revolutionary new way to light your garden to maximum effect with minimal fuss.</h3>
        </div>

        <div class="bottom">
              <div class="orangebartop">
                <h2>Total Control over WiFi</h2>
              </div>
              <div class="whitebartop">
                <h2>Plug &amp; Play System</h2>
              </div>
              <div class="orangebarbottom">
                <h2>Extra Safe 12v Power for DIY Installation</h2>
              </div>
              <div class="whitebarbottom">
                <h2>Prototypes available to Pre-Order</h2>
              </div>
        </div>
    
  </div>
</template>

<script>

import Carousel from '../components/Carousel.vue'

export default {
  name: 'Home',
  components: {
    Carousel
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: auto;
  
}

body {
  margin: 0;
  box-sizing: border-box;
}

.divider {
  background-color: #595a5c;
  position: relative;
  margin: auto;
  height: 32px;
}

.orangedivider{
  background-color: #c96a36;
  display: none;
  position: relative;
  margin: auto;
  width: 100%;
  height: 50px;
}

.cloud {
  background-image: url("/images/cloud.jpg");
  position: relative;
  height: 454px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloud h1 {
  color: white;
  font-size: 60px;
  font-family: din-2014;
  font-weight: 200;
  text-shadow: 2px 2px 4px rgb(66, 58, 58);
}

#litefirstwhitelogo {
  margin: auto;
}

.garden1 {
  background-image: url("/images/garden1.jpg");
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 652px;
  margin: auto;
}

.gardeninner {
  position: relative;
}

#garden1image {
  position: relative;
}

.garden1 h1 {
  max-width: 60vw;
  color: white;
  font-size: 60px;
  font-family: atten-round-new;
  font-weight: 500;
  font-style: normal;
  text-shadow: 2px 2px 4px black;

}

.carousel {
  height: 430px;
  margin: auto;
}



.lantern {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 500px;
  margin: 2rem;
  margin-top: 50px;
}

.lantern h3{
 padding: 3rem;
 font-size: 21px;
}

#lanternimage {
  position: relative;
  box-shadow: 2px 2px 5px #595a5c;
  width: 400px;
}

.bottom {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.orangebartop {
  background: #c96a36;
  position: relative; 
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 80px; 
  width: 100%;
}

.orangebartop h2 {
  
  color: white;
  font-weight: bold;
  font-size: 30pt;
}

.orangebarbottom {
  background: #c96a36;
  position: relative; 
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 80px; 
  width: 100%;
}

.orangebarbottom h2 {
  
  color: white;
  font-weight: bold;
  font-size: 30pt;
}

.whitebartop {
  position: relative; 
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 80px; 
}

.whitebartop h2 {
  
  color: black;
  font-weight: bold;
  font-size: 30pt;
}

.whitebarbottom {

  position: relative; 
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: auto;
  height: 80px; 
}

.whitebarbottom h2 {
  
  color: black;
  font-weight: bold;
  font-size: 30pt;
}

#speechmark1 {
  position: absolute;
  left: -4rem;
  top: 0;
  filter: drop-shadow(5px 5px 5px #222);
}

#speechmark2 {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  filter: drop-shadow(5px 5px 5px #222);
}

@media screen and (max-width: 900px){

  .orangebartop {
  height: 50px; 
}

.orangebarbottom {
  height: 50px; 
}

.whitebartop {
  width: auto;
  height: 50px; 
}

.whitebarbottom {
  width: auto;
  height: 50px; 
}

  .orangebartop h2 {
  
  color: white;
  font-weight: bold;
  font-size: 20pt;
}

.orangebarbottom h2 {
  
  color: white;
  font-weight: bold;
  font-size: 20pt;
}
.whitebartop h2 {
  
  color: black;
  font-weight: bold;
  font-size: 20pt;
}
.whitebarbottom h2 {
  
  color: black;
  font-weight: bold;
  font-size: 20pt;
}
}

@media screen and (max-width: 800px){

.lantern {
  margin: auto;
  width: 100%;
  height: 350px;
  padding-top: 6rem;

}

.lantern h3 {
  margin-right: 2rem;
  font-size: 15pt;
}

}

@media screen and (max-width: 600px){

.lantern {
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 6rem;

}

.lantern h3 {
  margin: 2rem;
  width: 80%;
  font-size: 18pt;
}
.bottom {

}
 
.orangedivider {
  display: block;
}

  .orangebarbottom {
    height: 160px;
    width:auto;
  
  }

  .orangebarbottom h3 {
    width: 80%;
  }

  .orangebartop {
  width: 100vw;
  
  }

  .orangebartop h3 {
    width: 80%;
    margin: auto;
  }

  .whitebartop .whitebarbottom{
    width: 100vw;
  }

  .cloud, .garden1{
    height: 100vw;
    background-size: cover;
  }

  .cloud h1, .garden1 h1{
    font-size: 10vw;
  }

  #litefirstwhitelogo{
    padding: 0 2rem;
    box-sizing: border-box;
  }

  #speechmark1, #speechmark2{
    max-width: 10vw;
  }
  #speechmark1{
    left: -1rem;
  }
}
</style>