<template>
<div>
  <div class="header">

      <div class="mobile-menu" @click="showMenu()">
        <img src="/images/burgermenu.svg">
      </div>      

      <router-link to="/">
        <div class="logo">
            <img src="/images/litefirstlogo.png">
        </div>
      </router-link>

      <div class="right">

        <div class="navbar" @click="hideCart">
          <router-link to="/">Home</router-link>
          <router-link to="/about-us">About us</router-link> 
          <router-link to="/our-product">Our Product</router-link> 
          <router-link to="/pre-order">Pre Order</router-link> 
        </div>

        <div class="mybasket" @click="showBasket">
          <img class="basketicon" src="/images/mybasket.jpg.png">
          <div class="basketnumber">
            {{ (getBasket.length == 0) ? 0 : getBasket.length - 1 }}
          </div>
        </div>

      </div>
      
  </div>

  <div class="spacer"></div>
  
  

  <div class="basket" v-if="basket">
    <h1>My Basket</h1>
    <div class="product" v-for="product in getBasket" :key="product.index">
    
        <div class="product-row">
          <div class="image">
              <img :src="'/images/products/' + product.name.replaceAll(' ', '-') + '.png'" id="text" v-if="product.name != 'Packing and delivery'">
            </div>
            <div class="name">
              {{ product.name }}
            </div>
            <div class="price">
              &pound;{{ product.price }}
            </div>
            <div class="delete" @click="deleteProduct(product.id)" v-if="product.name != 'Packing and delivery'">
              Delete
            </div>
            
          </div>
        </div>
        <div class="total">

          <h2>Total &pound;{{ getTotal.toFixed(2) }}</h2>

          <div class="checkout"> 
            <!-- Set up a container element for the button -->
            <div id="paypal-button-container"></div>
          </div>
            
        </div>
        <div class="emptybasket" v-if="getBasket.length==0">
          your basket is empty
        </div>
    </div>
      
  
  <div class="dividertop">
      <div class="smallmenu">
          <router-link to="/">Home</router-link>
          <router-link to="/about-us">About us</router-link> 
          <router-link to="/our-product">Our Product</router-link> 
          <router-link to="/pre-order">Pre Order</router-link> 
        </div>
  </div>

  <div class="menu" ref="menu" @click="hideMenu">
    <div class="menu-main">
      <img src="/images/litefirsrtwhitelogo.png">
      <ul>
        <ul><router-link to="/">Home</router-link></ul>
        <ul><router-link to="/about-us">About us</router-link> </ul>
        <ul><router-link to="/our-product">Our Product</router-link> </ul>
        <ul><router-link to="/pre-order">Pre Order</router-link> </ul>
      </ul>
    </div>
  </div>

</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  
  computed:{ //computed allows the values to constanty watch and the app to dynamically update values
    ...mapGetters({ //map getters helper method - see https://vuex.vuejs.org/guide/getters.html#the-mapgetters-helper
      getBasket: 'getBasket' //allows quick access to the basket. This can be used in the tempalte code. 
    }),
    
    getTotal: function(){
      let total = 0;

      this.getBasket.forEach( function( product ) {
        total +=parseFloat(product.price)
      } )

      return total
    }
    
  },
  methods:{
    showBasket: function(){
      this.basket=!this.basket

      this.$nextTick(() => {
        if( this.showBasket && this.getTotal > 0 )
        {
          document.getElementById( 'triggerPayPalButtons' ).click();
        }
      })

    },
    deleteProduct: function(id){
      this.$store.commit(
        'removeFromBasket',
        id
      );
    },
    showMenu:function(){
      this.$refs.menu.classList.add("menu-open");
    },
    hideMenu:function(){
      this.$refs.menu.classList.remove("menu-open");
      this.basket = false;
    },
    hideCart:function(){
      this.basket = false
    }
  },

  data:function(){
    return {
      basket:false,
    }
  },
  watch:{
    $route (){
        this.hideMenu();
        this.basket = false;
    }
  } 

}
</script>

<style scoped>

.header {
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: white;
  width: 100%;
  box-shadow: 0 0 1rem rgba(0,0,0,.25);
}

.spacer{
  height: 180px;
}

.header img{
    max-width: 75%;
}

.mobilemenu {
  display: none;
}

#burgermenu {
  width: 55px;
  height:51px;
  padding: 3rem 5rem;
  
}

.dividertop {
  background-color: #595a5c;
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 32px; 

}


.smallmenu {
  display: none;
  justify-content: space-around;
  color: white;
  width: 100%;
  height: 32px;
  position: relative;
  padding: 2rem;
}

.smallmenu a {
  color: white;
  font-size: 24px;
  font-family: din-2014;
  text-decoration: none;
  padding: 2rem;
}

.smallmenu a:hover {
  color: #c96a36;
}

.image {
  width: 150px;
  height: 150px;
}

.image img{
  max-height: 100%;
}

.right{
  display: flex;
  align-items: center;
  padding: 3rem 5rem 3rem 0;
  justify-content: flex-end;
}

.logo {
  padding: 3rem 0 3rem 5rem;
  
}

.navbar {
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  width: 500px;
}

.navbar a {
  color: black;
  font-size: 24px;
  font-family: din-2014;
  text-decoration: none;
}

.navbar a:hover {
  color: #c96a36;
}

.mybasket {
  position: relative;
}

.basket {
  width: 100%;
  font-family: bebas-neue-rounded;
  font-size: 25pt;
  background-color: #e6e7e8;
}

.basket > h1 {
  color: #c96a36;
  padding-top:2rem;
  margin: 0;
}

.checkout {
  margin: auto;
  display: flex;
  position: relative;
  width: 200px;
  text-align: center;
  justify-content: center;
  border-radius: 20px;
}

.checkout > button {
  background-color: #c96a36;
  width: 200px;
  height: 50px;
  color: white;
  font-family: bebas-neue-rounded;
  font-size: 2.5rem;
}

.basketnumber {
  position: absolute;
  color: white;
  font-weight: 900;
  right: 28%;
  top: 22%;
}

.product {
  height: 200px;
  width: 1200px;
  max-width: 100%;
  display: flex;
  flex-direction:row;
  justify-content: space-around;
  margin: auto;
  background-color: #e6e7e8;
  padding:0rem;
  font-family: din-2014;
}

.product-row{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e7e8;
  font-family: din-2014;
  font-size: 2rem;
  width: 100%;
}

.product > h1 {
  font-family: bebas-neue-rounded;
  color: #c96a36;
  font-size: 50pt;
}

.name {
  justify-content: center;
  align-items: center;
  width: 30%;
}

.price {
  justify-content: center;
  align-items: center;
  width: 15%;

}

.delete {
  justify-content: center;
  align-items: center;
  width: 15%;
  cursor: pointer;
}

.delete:hover {
  color: #c96a36;
}

.total{
  font-size: 25pt;
  padding-bottom: 2rem;
}

.mobile-menu{
  display: none;
}

.menu{
  display: none;
}



  @media screen and (max-width: 1200px){
    .logo {
      padding: 3rem 0 3rem 2rem;
    }

    .right{
      padding: 3rem 2rem 3rem 0;
    }
  
  }

  @media screen and (max-width: 1024px){
    
    .header {
      margin: auto;
      display: flex;
    }

    .spacer{
      height: 170px;
    }
    
    .navbar{
      width: auto;
    }

    .navbar a {
      float: left;
      text-align: center;
      padding: 10px 10px;
     
  
    }

    .logo img{
      width: 16rem;
    }
  }

    @media screen and (max-width: 900px){
      .mobilemenu {
        display: block;
      }
      .navbar{
        display: none;
      }

      .smallmenu {
        display: block;
      }

      #burgermenu{
        padding: 0 0 0 2rem;
      }

      .logo{
        padding: 1rem 0;
      }

      .right{
        padding: 0 2rem 0 0;
      }

      .product-row{
        font-size: 1.5rem;
      }

      .spacer{
        height: 105px;
      }
    }

    @media screen and (max-width: 700px){
      .smallmenu a{
        font-size: 24px;
        padding: 1rem;

      }
    }

    @media screen and (max-width: 600px){
      #burgermenu{
        height: 30px;
        width: 30px;
        padding: 0 0 0 1rem;
      }

      .right{
        padding: 0 1rem 0 0;
      }

      .smallmenu a{
        font-size: 24px;
        padding: 1rem;
        
      }

      .product{
        height: auto;
      }

      .product-row{
        flex-direction: column;
        border: 1px solid black;
        border-radius: 0.5rem;
        margin: rem;
        padding: 0.5rem;
      }

      .name, .price, .delete{
        width: 100%;
      }

      .delete{
        font-size: 1rem;
        color: red;
        padding: 0.5rem;
      }
    }

    @media screen and (max-width: 575px){
          .smallmenu a{
        font-size: 22px;
        padding: 0.3rem;
        
      } 
    }

    @media screen and (max-width: 500px){
      .smallmenu{
        display: none;
      }

      .mobile-menu{
        display: block;
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: 3rem;
        height: 3rem;
        padding: 1rem;
      }

      .mobile-menu img{
        width: 2rem;
        height: 2rem;
      }

      .logo{
        padding: 1rem 1rem 1rem 2rem;
      }

      .menu{
        display: block;
        position: fixed;
        left: -100vw;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 102;
        transition: 250ms ease-in-out;
      }

      .menu-main{
        background: #c96a36;
        width: 75%;
        height: 100%;
      }

      .menu-open{
        left: 0;
      }


      .menu img{
        padding: 3rem;
        max-width: 100%;
        box-sizing: border-box;
      }

      .menu ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 50vh;
        font-size: 4rem;
        margin: 0;
        padding: 3vh 0;
        font-family: turbinado-pro;
      }

      .menu a{
        text-decoration: none;
        color: white;
      }

    }


 @import url("https://use.typekit.net/fja2htz.css");
</style>
