<template>
<div class="vue-flux-carousel">
    <vue-flux
    :options="vfOptions"
    :images="vfImages"
    :transitions="vfTransitions"
    style="width:100%;height: 100%"
    ref="slider">

    <template v-slot:preloader>
        <flux-preloader />
    </template>

    <template v-slot:caption>
        <flux-caption />
    </template>

    <template v-slot:controls>
        <flux-controls />
    </template>

    <template v-slot:pagination>
        <flux-pagination />
    </template>

    <template v-slot:index>
        <flux-index />
    </template>
    </vue-flux>
</div>
    
</template>

<script>
import {
   VueFlux,
   FluxCaption,
   FluxControls,
   FluxIndex,
   FluxPagination,
   FluxPreloader
} from 'vue-flux';

export default {
    components: {
      VueFlux,
      FluxCaption,
      FluxControls,
      FluxIndex,
      FluxPagination,
      FluxPreloader,
    },
data: () => ({
    vfOptions: {
        autoplay: true,
        path: '/images/gallery/'
    },
    vfImages: [ 
        'slideshow1.jpg', 
        'slideshow2.jpg', 
        'slideshow3.jpg',
        'lit-day_1.jpg',
        'lit-day_2.jpg',
        'lit-day_3.jpg',
        'lit-day_4.jpg',
        'lit-evening.jpg',
        'lit-night_1.jpg',
        'lit-night_2.jpg',
        'unlit_day.jpg',

        ],
    vfTransitions: [ 'cube' ],
}),
}
</script>

<style scoped>

.vue-flux-carousel {
    margin: 1rem auto;
    height: 400px;
    max-width: 400px;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
}

@media screen and (max-width: 450px){
    .vue-flux-carousel {
        height: 80vw;
        max-width: 80vw;
    }
}

</style>

