<template>
<div>
  <div class="footer">
      <div>+44 (0)7714 512434 <br> keith.watkins@litefirst.co.uk</div>
      <div>Company No  12034248 <br>
        VAT No 326237806</div>
      <div>Litefirst Ltd, Attention : Powell & Associates, 28-29 New Road, Kidderminster, Worcestershire. DY10 1AF United Kingdom</div>
  </div>
  

</div>
</template>

<script>


export default {
  
  
}
</script>

<style scoped>
.footer {
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.footer > div {
  max-width: 50%;
  margin: 2rem;
  text-align: center;
  line-height: 40px;
  font-size: 1.3rem;
}

@media screen and (max-width: 900px){
    .footer > div {
  width: auto;
  margin: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
}

.footer {
  flex-direction: column
}
}

@media screen and (max-width: 530px){
    .footer > div {
  width: auto;
  margin: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
}

.footer {
  flex-direction: column
}
}



 @import url("https://use.typekit.net/fja2htz.css");
</style>
